.p-fileupload-choose {
  font-size: 2.4em !important;
}
.p-button-icon-left {
  font-size: 1em !important;
}
.progress-container {
  width: 100%;
  margin: auto;
}

@media (min-width: 780px) {
  .progress-container {
    width: 60%;
  }
}
