body {
    background-color: #fff;
}

.full-width {
    min-width: 88%;
    max-width: 100%;
    width: 100%;
}

body .pi {
    font-size: 1.25rem;
}
